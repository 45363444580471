/**
 * The landing page
 *
 */

// IMPORTS
import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Element, Events} from "react-scroll";
import {Waypoint} from "react-waypoint";
import moment from "moment";

import {
  SEO,
  GlobalStyle,
  NavigationHeader,
  MainstageVideo,
  CategoryListWithQuery,
  ConsentBanner,
  USPMessage,
  Schedule,
  Infographics,
  ContactListWithQuery,
  Rundown,
  // Grid
} from "../components";

import {AppState} from "../store/";
import {
  updateRegionAction,
  setTimezoneDetectedAction,
  updateUTCOffsetAction,
  updateIsNavigationInvertedAction,
  updateIsNavigationHiddenAction,
  updateIsHomepageAboveVideoAction,
  updateLocalUTCOffsetTextAction,
} from "../store/system/actions";
import {
  LandingPageResponseProps,
  DflRegionType,
  dflRegions,
  fullPageSections,
  px,
  breakpoints,
  MainContentWrapper,
} from "../models";
import {trackRegion} from "../util/tracking";
import {getDflRegionByTimezone, formatUTCOffsetText} from "../util/util";
import {scheduleContent, rundownContent} from "../util/content";

// STYLES
interface FullPageProps {
  forceFullHeight?: boolean;
  noOverflow?: boolean;
}
const FullPage = styled.div`
  width: 100%;
  height: ${(props: FullPageProps) => (props.forceFullHeight ? "100vh" : "unset")};
  position: relative;
  overflow: ${(props: FullPageProps) => (props.forceFullHeight ? "hidden" : "visible")};

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    height: 100vh;
  }
`;

const CategoryListWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 100px 20px 20px 20px;
  box-sizing: border-box;
`;

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
  timezoneDetected: boolean;
  isHomepageAboveVideo: boolean;
  localUTCOffsetText: string;
}
const mapStateToProps = (state: AppState): PropsFromState => {
  return {
    selectedRegion: state.system.selectedRegion,
    timezoneDetected: state.system.timezoneDetected,
    isHomepageAboveVideo: state.system.isHomepageAboveVideo,
    localUTCOffsetText: state.system.localUTCOffsetText,
  };
};

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  setTimezoneDetected: () => void;
  updateUTCOffset: (newOffset: number) => void;
  updateLocalUTCOffsetText: (newLocalUTCOffsetText: string) => void;
  updateIsNavigationInverted: (isInverted: boolean) => void;
  updateIsNavigationHidden: (isHidden: boolean) => void;
  updateIsHomepageAboveVideo: (isAboveVideo: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    setTimezoneDetected: () => {
      return dispatch(setTimezoneDetectedAction());
    },
    updateUTCOffset: (newOffset: number) => {
      return dispatch(updateUTCOffsetAction(newOffset));
    },
    updateLocalUTCOffsetText: (newLocalUTCOffsetText: string) => {
      return dispatch(updateLocalUTCOffsetTextAction(newLocalUTCOffsetText));
    },
    updateIsNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
    updateIsNavigationHidden: (isHidden: boolean) => {
      return dispatch(updateIsNavigationHiddenAction(isHidden));
    },
    updateIsHomepageAboveVideo: (isAboveVideo: boolean) => {
      return dispatch(updateIsHomepageAboveVideoAction(isAboveVideo));
    },
  };
};

interface IndexPageProps extends PropsFromState, PropsFromDispatch {
  data: LandingPageResponseProps;
}

// COMPONENT
class IndexPage extends React.Component<IndexPageProps> {
  productsElementRef = null;

  componentDidMount() {
    const {updateLocalUTCOffsetText, updateUTCOffset} = this.props;
    const date = moment
      .utc()
      .local()
      .format();
    const utcOffset = moment.parseZone(date).format("Z");
    const formattedUTCOffset = formatUTCOffsetText(utcOffset);

    // get Timezone and utc offset once
    if (!this.props.timezoneDetected) {
      const dflRegion = getDflRegionByTimezone();
      this.props.updateRegion(dflRegion);

      if (moment && moment().utcOffset() !== null) {
        // ignore daylight savings time
        const isDayLightSavings = moment().isDST();
        const dayLightSavingsOffset = isDayLightSavings ? 1 : 0;
        updateUTCOffset(moment().utcOffset() / 60 - dayLightSavingsOffset);
        updateLocalUTCOffsetText(formattedUTCOffset);
      }
      this.props.setTimezoneDetected();
    }

    // unhide the menu if it is hidden
    this.props.updateIsNavigationHidden(false);
    this.props.updateIsNavigationInverted(true);

    trackRegion(this.props.selectedRegion);
  }

  componentWillUnmount() {
    // remove event listeners when unmounting the component (e.g. switching to another page)
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  render() {
    const {data, updateIsNavigationInverted, selectedRegion, updateIsHomepageAboveVideo} = this.props;
    const fromAcf = data.wpPage;

    return (
      <div>
        <GlobalStyle />
        <ConsentBanner />
        <SEO title="Home" />
        <NavigationHeader />

        <MainContentWrapper>
          <Element name={fullPageSections.mainStage}>
            <FullPage forceFullHeight={true}>
              <MainstageVideo
                srcHorizontal={fromAcf.mainstageVideo.mainstageVideoEmea.mediaItemUrl}
                srcVertical={fromAcf.mainstageVideo.verticalVideoEmea.mediaItemUrl}
              />
            </FullPage>
          </Element>

          <Element name={fullPageSections.categories}>
            <Waypoint
              topOffset="10%"
              onEnter={(e: Waypoint.CallbackArgs) => {
                if (e.currentPosition === "inside") {
                  updateIsHomepageAboveVideo(true);
                  updateIsNavigationInverted(true);
                }
              }}
              onLeave={(e: Waypoint.CallbackArgs) => {
                if (e.currentPosition === "above") {
                  updateIsNavigationInverted(false);
                  updateIsHomepageAboveVideo(false);
                }
              }}
            />
            <FullPage>
              <CategoryListWrapper ref={ref => (this.productsElementRef = ref)}>
                <CategoryListWithQuery />
              </CategoryListWrapper>
            </FullPage>
          </Element>

          <Element name={fullPageSections.uspMessage}>
            <FullPage>
              <USPMessage
                message={
                  selectedRegion === dflRegions.emea
                    ? fromAcf.uspMessage.uspMessageEmea
                    : selectedRegion === dflRegions.americas
                    ? fromAcf.uspMessage.uspMessageAmericas
                    : selectedRegion === dflRegions.asia
                    ? fromAcf.uspMessage.uspMessageAsia
                    : ""
                }
              />
            </FullPage>
          </Element>

          <Element name={fullPageSections.rundown}>
            <FullPage>
              <Rundown headline={fromAcf.rundown.rundownHeadline} intro={fromAcf.rundown.rundownIntro} {...rundownContent} />
            </FullPage>
          </Element>

          <Element name={fullPageSections.schedule}>
            <FullPage>
              <Schedule
                {...scheduleContent}
                pdfUrl={fromAcf.schedule.schedulePdf.mediaItemUrl}
                title={fromAcf.schedule.scheduleHeadline}
              />
            </FullPage>
          </Element>

          <Element name={fullPageSections.infographics}>
            <Infographics
              headline={fromAcf.infographics.infographicsHeading}
              subheadline={fromAcf.infographics.infographicsDescription}
              content={
                selectedRegion === dflRegions.emea
                  ? fromAcf.infographics.emeaInfographicsRepeater[0].emeaInfographicsCloneInfographicsTemplate
                  : selectedRegion === dflRegions.americas
                  ? fromAcf.infographics.americasInfographicsRepeater[0].americasInfographicsCloneInfographicsTemplate
                  : selectedRegion === dflRegions.asia
                  ? fromAcf.infographics.asiaInfographicsRepeater[0].asiaInfographicsCloneInfographicsTemplate
                  : null
              }
              footnote={
                selectedRegion === dflRegions.emea
                  ? fromAcf.infographics.emeaFootnote
                  : selectedRegion === dflRegions.americas
                  ? fromAcf.infographics.americasFootnote
                  : selectedRegion === dflRegions.asia
                  ? fromAcf.infographics.asiaFootnote
                  : null
              }
            />
          </Element>

          <Element name={fullPageSections.contact}>
            <FullPage>
              <ContactListWithQuery />
            </FullPage>
          </Element>
        </MainContentWrapper>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query ($homepage_id: Int = 41) {
  wpPage(databaseId: {eq: $homepage_id}) {
    slug
    databaseId
    uspMessage {
      uspMessageEmea
      uspMessageAsia
      uspMessageAmericas
    }
    rundown {
      rundownIntro
      rundownHeadline
    }
    staticPage {
      slug
      staticpageContent
    }
    title
    uri
    content
    mainstageVideo {
      mainstageVideoAmericas {
        mediaItemUrl
      }
      mainstageVideoAsia {
        mediaItemUrl
      }
      mainstageVideoEmea {
        mediaItemUrl
      }
      verticalVideoAmericas {
        mediaItemUrl
      }
      verticalVideoAsia {
        mediaItemUrl
      }
      verticalVideoEmea {
        mediaItemUrl
      }
    }
    infographics {
      infographicsHeading
      infographicsDescription
      americasFootnote {
        lineOfText
      }
      americasInfographicsRepeater {
        americasInfographicsCloneInfographicsTemplate {
          heading
          description
          barChartOrImage
          barChartMaxValue
          barChart {
            barColor
            label
            value
          }
          image {
            sourceUrl
          }
        }
      }
      asiaFootnote {
        lineOfText
      }
      asiaInfographicsRepeater {
        asiaInfographicsCloneInfographicsTemplate {
          barChartMaxValue
          barChartOrImage
          description
          heading
          barChart {
            barColor
            label
            value
          }
          image {
            sourceUrl
          }
        }
      }
      emeaFootnote {
        lineOfText
      }
      emeaInfographicsRepeater {
        emeaInfographicsCloneInfographicsTemplate {
          barChartMaxValue
          barChartOrImage
          description
          heading
          barChart {
            barColor
            label
            value
          }
          image {
            sourceUrl
          }
        }
      }
    }
    schedule {
      scheduleHeadline
      schedulePdf {
        mediaItemUrl
      }
    }
  }
}
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
